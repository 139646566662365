<template>
	<div class="staff-asset-box page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<div class="page-desc">
		  当前登录账号下所有的资产数据
		</div>  
		<div class="search-form">
			<el-form ref="form" :inline="true" :model="search_key" prop="">
				<el-form-item label="">
					<el-input v-model="search_key.asset_code" placeholder="输入关键字搜索"></el-input>
				</el-form-item>
				<el-button type="primary" @click="onLoad()">查询</el-button>
			</el-form>
		</div>
		<el-empty :image-size="150" v-if="assetList.length <1"></el-empty>
		<div class="asset-box">
			<el-row style="border-bottom: 1px solid #C0C4CC;" v-for="item in assetList">
				<el-col :span="24">
					<div class="grid-content  bg-purple-dark">
						<el-tag type="warning">{{item.status_text}}</el-tag>
						<span  style="margin-left: 10px;font-size: 18px;color: #303133;font-weight: bold;">{{item.asset_code}}</span> 
					</div>   
				</el-col>
				<el-col :span="6">
					<div class="grid-content  bg-purple-dark">
						<span class="title">资产分类:{{item.cate_name}}</span>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content  bg-purple-dark">
						<span class="title">资产名称:{{item.cate_name}}</span> 
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content  bg-purple-dark">
						<span class="title">品牌:{{item.brand}}</span>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content  bg-purple-dark">
						  <el-button type="primary" size="mini" @click="showDetails(item.id)">查看详情</el-button>
					</div>
				</el-col>
				<el-col :span="6"> 
					<div class="grid-content  bg-purple-dark">
						<div class="grid-content  bg-purple-dark">
							<span class="title">所在位置:{{item.location_name}}</span>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<AddVue ref="childDialog"></AddVue>
	</div>
</template>

<script>
	import AddVue from "../AssetList/Details.vue"
	export default {
		inject: ['reload'],
		components: {
			AddVue
		},
		data() {
			return {
				search_key: {
					asset_code: ""
				},
				assetList:[]
			}
		},
		
		created() {
			
			this.onLoad()
		},
		mounted() {

		},
		methods: {
			onLoad() {
				this.$api.post('AssetList/staffAsset',{
					asset_code:this.search_key.asset_code
				},res=>{
					if(res.code == 200){
						this.assetList = res.data.list
					}
				})	
			},
			showDetails(id){
				this.$refs.childDialog.handleShow(id)
			}
		},
	}
</script>
<style scoped lang="scss">
	.staff-asset-box {
		font-size: 14px;
		.asset-box {
			// border-bottom: 1px solid #C0C4CC;
			.title{
				color: #606266;
			}
			.el-row{
				.el-col{
					height: 40px;
					line-height: 40px;
				}
			}
			
		}
	}
</style>
