<template>
	<div>
		<el-dialog title="资产详情" :visible.sync='dialogShowVisible' width="35%"  >
			<div class="asset-details-box">
				<el-row v-for="item in info">
					<el-col :span="12" >
						<div class="grid-content  bg-purple-dark">
							<span class="title">{{item.title}}</span>
						</div>
					</el-col>
					<el-col :span="12" >
						<div class="grid-content  bg-purple-dark">
							<span class="title">{{item.value}}</span>
						</div>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
    data() {
        return {
			dialogShowVisible:false,
			info:[]
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		handleShow(id){
			this.$api.get('/AssetList/details',{id:id},res=>{
				console.log(res)
				if(res.code == 200){
					this.info  = res.data.info
					this.dialogShowVisible =  true
				}
			})
		}
    },
}
</script>
<style scoped lang="scss">
	.asset-details-box {
		font-size: 14px;
		.el-row{
			.el-col{
				height: 30px;
				line-height: 30px;
			}
		}
			
		
	}
</style>